import { Flex, Text, Button } from "@chakra-ui/react";
import { useGetAvailableTickets, useGetNumberOfTicketsIown, useGetTicketsPerRound, useGetLastWinnerAddress, useGetLastWinnerName } from "../hooks";
import { useEthers } from "@usedapp/core";

export default function TicketInformation() {
    const account           = useEthers();
    const availableTickets  = useGetAvailableTickets();
    const ticketsPerRound   = useGetTicketsPerRound();
    const lastWinnerName    = useGetLastWinnerName();
    const lastWinnerAddress = useGetLastWinnerAddress();
    const ticketsIown       = useGetNumberOfTicketsIown(account.account);
  if(account.account)
    return (
      <Flex direction="column" align="center" mt="4">
        <Text color="teal" fontSize="4xl">
          You own {ticketsIown ? ticketsIown.toNumber() : null} tickets
        </Text>      
        <Text color="green" fontSize="1xl">
          Last winner: {lastWinnerName ? lastWinnerName : null}           {lastWinnerAddress &&
              `${lastWinnerAddress.slice(0, 6)}...${lastWinnerAddress.slice(
                lastWinnerAddress.length - 4,
                lastWinnerAddress.length
              )}`}
        </Text>     
        <Text color="grey" fontSize="1xl">
          {availableTickets ? ticketsPerRound.toNumber() - availableTickets.toNumber() : null} of {ticketsPerRound ? ticketsPerRound.toNumber() : null} tickets purchased
        </Text>    
        {/* <Button colorScheme="teal" size="lg">
          Get Available Tickets
        </Button> */}
      </Flex>
    );
  else
    return null;
}