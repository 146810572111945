import { Table, TableCaption, Thead, Tr, Th, Tbody, Td, Link } from "@chakra-ui/react";
import { useGetPurchasedTickets, useGetAvailableTickets, useGetTicketsPerRound } from "../hooks";
import { useEthers } from "@usedapp/core";

export default function PurchasedTicketsTable() {
  var purchasedTicketsElements  = [];
  const account = useEthers();

  const purchasedTickets = useGetPurchasedTickets();
  const numberOfTicketsPurchased = useGetTicketsPerRound() - useGetAvailableTickets();
  for(var i = 0; i < numberOfTicketsPurchased; ++i) {
    let url =  "https://polygonscan.com/address/" + purchasedTickets[0][i][0];
    let address = purchasedTickets[0][i][0];
    purchasedTicketsElements.push(
    <Tr key={i}>
        <Td isNumeric>{i+1}</Td>
        <Td>{purchasedTickets[0][i][1]}</Td>
        <Td><Link color='teal' href={url}>{address &&
              `${address.slice(0, 6)}...${address.slice(
                address.length - 4,
                address.length
              )}`}</Link></Td>
    </Tr>
    );
  }
  if(account.account)
    return (
        <Table mt="4" size="sm" width="sm" variant='simple' color="white" colorScheme='teal'>
        <TableCaption>Tickets for current round</TableCaption>
        <Thead>
            <Tr>
            <Th isNumeric>#</Th>
            <Th>Name</Th>
            <Th>Address</Th>
            </Tr>
        </Thead>
        <Tbody>
            {purchasedTicketsElements}
        </Tbody>
        </Table>
    );
  else
    return null;
}
