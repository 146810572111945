// hooks/index.ts
import { ethers } from "ethers";
import { useContractCall, useContractFunction } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import lotteryContractAbi from "../abi/LotteryContract.json";
import { lotteryContractAddress } from "../contracts"

const lotteryContractInterface = new ethers.utils.Interface(lotteryContractAbi);
const contract = new Contract(lotteryContractAddress, lotteryContractInterface);

export function useGetAvailableTickets() {
  const [availableTickets]: any = useContractCall({
    abi: lotteryContractInterface,
    address: lotteryContractAddress,
    method: "getAvailableTickets",
    args: [],
  }) ?? [];
  return availableTickets;
}

export function useGetTicketsPerRound() {
    const [ticketsPerRound]: any = useContractCall({
      abi: lotteryContractInterface,
      address: lotteryContractAddress,
      method: "ticketsForRound",
      args: [],
    }) ?? [];
    return ticketsPerRound;
}

export function useGetNumberOfTicketsIown(account: any) {
    const [tickets]: any = useContractCall({
      abi: lotteryContractInterface,
      address: lotteryContractAddress,
      method: "howManyTicketsHaveIBought",
      args: [account],
    }) ?? [];
    return tickets;
}

export function useGetLastWinnerName() {
    const [name]: any = useContractCall({
        abi: lotteryContractInterface,
        address: lotteryContractAddress,
        method: "lastWinnerName",
        args: [],
      }) ?? [];
      return name;
}

export function useGetLastWinnerAddress() {
    const [name]: any = useContractCall({
        abi: lotteryContractInterface,
        address: lotteryContractAddress,
        method: "lastWinnerAddress",
        args: [],
      }) ?? [];
      return name;
}

export function usePurchaseTickets(name: string, quantity: number) {
    const [success]: any = useContractCall({
        abi: lotteryContractInterface,
        address: lotteryContractAddress,
        method: "purchaseTicket",
        args: [quantity, name]
      }) ?? [];
    return success;
}

export function useGetNumberOfWinners() {
  const [numberOfWinners]: any = useContractCall({
    abi: lotteryContractInterface,
    address: lotteryContractAddress,
    method: "numberOfWinners",
    args: [],
  }) ?? [];
  return numberOfWinners;
}

export function useGetWinner(index: number) {
  const [winner]: any = useContractCall({
    abi: lotteryContractInterface,
    address: lotteryContractAddress,
    method: "winners",
    args: [index],
  }) ?? [];
  return winner;
}

export function useGetPurchasedTicket(index: number) {
  const purchasedTickets: any = useContractCall({
    abi: lotteryContractInterface,
    address: lotteryContractAddress,
    method: "purchasedTickets",
    args: [index],
  }) ?? [];
  return purchasedTickets;
}

export function useGetPurchasedTickets() {
  const purchasedTickets: any = useContractCall({
    abi: lotteryContractInterface,
    address: lotteryContractAddress,
    method: "getPurchasedTickets",
    args: [],
  }) ?? [];
  return purchasedTickets;
}

export function useGetWinners() {
  const winners: any = useContractCall({
    abi: lotteryContractInterface,
    address: lotteryContractAddress,
    method: "getWinners",
    args: [],
  }) ?? [];
  return winners;
}

export function useGetLotteryAmount() {
  const [lotteryAmount]: any = useContractCall({
    abi: lotteryContractInterface,
    address: lotteryContractAddress,
    method: "lotteryAmount",
    args: [],
  }) ?? [];
  return lotteryAmount;
}

export function useContractMethod(methodName: string) {
    const { state, send } = useContractFunction(contract, methodName, {});
    console.log(state);
    return { state, send };
  }