import { useState } from "react";
import { useGetAvailableTickets } from "../hooks";
import {
  Box,
  Flex,
  Text,
  Button,
  NumberInput,
  NumberInputField,
  Input,
  Image
} from "@chakra-ui/react";
import { useContractMethod } from "../hooks";
import { utils } from "ethers";
import { useEthers } from "@usedapp/core";

export default function PurchaseTickets() {
  const account = useEthers();
  const { state: setPurchaseTicketState, send: purchaseTicket } = useContractMethod("purchaseTicket");
  const [quantity, setInput] = useState("");
  const ticketsForSale = useGetAvailableTickets();

  const [name, setValue] = useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  function handlePurchaseTicket() {
    const _count    = parseInt(quantity);
    const _price    = (_count * 0.05).toFixed(4);
    if (_count) {
      purchaseTicket(_count, name, {
        value: utils.parseEther(_price.toString()),
      });
    }
  }

  function handleInput(valueAsString: string, valueAsNumber: number) {
    setInput(valueAsString);
  }
  if(account.account)
    return (
        <Flex direction="column" align="center" mt="4">
        <Box mt={4}>
            <Text color="white">Name:</Text>
            <Input
            value={name}
            onChange={handleChange}
            color="white"
            clampValueOnBlur={false}
            >
            </Input>
            <Text color="white">Quantity:</Text>
            <NumberInput
            mb={2}
            min={1}
            max={ticketsForSale}
            value={quantity}
            onChange={handleInput}
            color="white"
            clampValueOnBlur={false}
            >
            <NumberInputField />
            </NumberInput>
            <Button isFullWidth colorScheme="purple" onClick={handlePurchaseTicket}>
            Purchase (0.05 MATIC/ticket)
            </Button>
        </Box>
            {/* <Box boxSize='300px'>
                <Image mt="4" src='https://c.tenor.com/2UTRqgf1gokAAAAC/the-simpsons-money.gif' />
            </Box> */}
        </Flex>
    );
   else
    return null;
}