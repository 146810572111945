import { Table, TableCaption, Thead, Tr, Th, Tbody, Td, Link } from "@chakra-ui/react";
import { useGetWinners, useGetNumberOfWinners } from "../hooks";
import { useEthers } from "@usedapp/core";

export default function WinnerTable() {
  var winnerElements        = [];
  const winners             = useGetWinners();
  const numberOfWinners     = useGetNumberOfWinners();
  const account             = useEthers();

  for(var i = numberOfWinners-1; i > -1; --i) {                         // Show recent winners at the top
    let url =  "https://polygonscan.com/address/" + winners[0][i][0];
    let address = winners[0][i][0];
    winnerElements.push(
    <Tr key={i}>
        <Td>{winners[0][i][1]}</Td>
        <Td><Link color='teal' href={url}>{address &&
              `${address.slice(0, 6)}...${address.slice(
                address.length - 4,
                address.length
              )}`}</Link></Td>
        <Td isNumeric>{Number(winners[0][i][2].toString())/1000000000000000000}</Td>    {/*Convert wei to MATIC*/}
    </Tr>
    );
  }
  
  if(account.account)
    return (
        <Table mt="4" size="sm" width="sm" variant='simple' color="white" colorScheme='teal'>
        <TableCaption>Previous Winners</TableCaption>
        <Thead>
            <Tr>
            <Th>Name</Th>
            <Th>Address</Th>
            <Th isNumeric>MATIC</Th>
            </Tr>
        </Thead>
        <Tbody>
            {winnerElements}
        </Tbody>
        </Table>
    );
  else
    return null;
}