import { ChakraProvider, useDisclosure, Image, Box } from "@chakra-ui/react";
// import theme from "./theme";
import Layout from "./components/Layout";
import ConnectButton from "./components/ConnectButton";
// import AccountModal from "./components/AccountModal";
// import "@fontsource/inter";
import TicketInformation from "./components/TicketInformation";
import PurchaseTickets from "./components/PurchaseTickets";
import Particles from "react-tsparticles";
import PurchasedTicketsTable from "./components/PurchasedTicketsTable";
import WinnerTable from "./components/WinnerTable";

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    
    <ChakraProvider>
      <Layout>
        <ConnectButton />
        <TicketInformation />
        <PurchaseTickets />
        <PurchasedTicketsTable />
        <WinnerTable />
      </Layout>
    </ChakraProvider>
  );
}

export default App;